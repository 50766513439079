import Vue from 'vue'
import { ActionTree, MutationTree } from 'vuex'
import { RootState } from '@/store/types'
import { AxiosResponse, AxiosError } from 'axios'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import { fill, merge } from 'lodash'

interface CardSeriesFormState {
  loaded: boolean;
  loading: boolean;
  fatalError: boolean;
  serverErrors: any;

  object: any;
  form: any;

  lastMeta: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };

  merchant: any[];
  card_series: any;
}

const $state: CardSeriesFormState = {
  loading: false,
  loaded: false,
  fatalError: false,
  serverErrors: null,

  object: null,
  form: null,

  lastMeta: {
    page: 0,
    pageSize: 0,
    totalCount: 0,
    totalPages: 0,
  },

  merchant: [],
  card_series: {},
}


const initState = cloneDeep($state)


const EMPTY_OBJECT = {
  name: null,
  hostname: null,
  start_number: null,
  end_number: null,
  is_autogen: false,
}

const AVAILABLE_FIELDS = ['name', 'hostname', 'start_number', 'end_number', 'is_autogen']

export const $actions: ActionTree<CardSeriesFormState, RootState> = {

  initState({ commit, state }, { id, predefined }) {
    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      if (id) {
        vm.$http
          .get(`/admins/rest/v1/card_series/${id}`)
          .then((response: AxiosResponse) => {
            commit('SET_FORM_DATA', {
              object: response.data.card_series,
            })
            commit('LOADED_SUCCESS')

            resolve(state.object)
          })
          .catch((error: AxiosError) => {
            // tslint:disable-next-line:no-console
            console.error('Fatal error', error.response?.data)
            commit('LOADED_ERROR')
            reject(error.response?.data)
          })
      }
      else {
        return new Promise((resolve, reject) => {
          commit('GENERATE_FORM_DATA', predefined)
          commit('LOADED_SUCCESS')
          resolve(state.object)
        })
      }
    })
  },

  loadMerchants({ commit, state }, { id, predefined }) {

    return new Promise((resolve, reject) => {
      const vm = (this as any)._vm

      const params: any = {
        per_page: 1000,
        with_outlets: true,
      }

      commit('START_LOADING')

      vm.$http
        .get('/admins/rest/v1/merchants', { params })
        .then((response: AxiosResponse) => {
          commit('SET_ITEMS_LIST', response.data)
          commit('LOADED_SUCCESS')

          resolve()
        })
        .catch((error: any) => {
          // tslint:disable-next-line:no-console
          console.error('Fatal error', error)
          commit('LOADED_ERROR')
        })
    })
  },

  prepareNew({ commit, state }, predefined) {
    return new Promise((resolve, reject) => {
      commit('GENERATE_FORM_DATA', predefined)
      commit('LOADED_SUCCESS')
      resolve()
    })
  },

  changeFormValue({ commit }, { field, newValue }) {
    commit('CHANGE_VALUE', { field, newValue })
  },

  saveObject({ commit, state }, id) {
    const vm = (this as any)._vm
    return Promise.all(Object.keys(state.card_series).map((key: any) => {
      const series = state.card_series[key]
      const cleanForm: any = Object.assign({}, series)
      let request = null
      for (const key of AVAILABLE_FIELDS) {
        cleanForm[key] = state.form[key]
      }
      request = vm.$http.post(`/admins/rest/v1/card_series`, {
        card_series: cleanForm,
      })
      request.then((response: AxiosResponse) => {
        commit('LOADED_SUCCESS')
      })
        .catch((errorResponse: AxiosError) => {
          commit('LOADED_ERROR')
        })
      return request
    }))

    // return new Promise((resolve, reject) => {
    //     const vm = (this as any)._vm;

    //     const cleanForm: any = {}
    //     for (const key of AVAILABLE_FIELDS) {
    //         cleanForm[key] = state.form[key];
    //     }

    //     commit('START_LOADING');

    //     let request = null;

    //     if (id) {
    //         request = vm.$http.patch(`/admins/rest/v1/card_series/${id}` , {
    //             card_series: cleanForm,
    //         });

    //     } else {
    //         request = vm.$http.post(`/admins/rest/v1/card_series` , {
    //             card_series: cleanForm,
    //         });
    //     }

    //     request
    //         .then((response: AxiosResponse) => {
    //             commit('LOADED_SUCCESS');

    //             resolve(response.data);
    //         })
    //         .catch((errorResponse: AxiosError) => {
    //             // eslint-disable-next-line
    //             console.error('Fatal error', errorResponse);

    //             if (errorResponse.response?.status === 500) {
    //                 commit('LOADED_ERROR');
    //                 reject();
    //             } else {
    //                 const serverError = errorResponse.response?.data?.error;

    //                 commit('LOADED_SUCCESS');
    //                 commit('SET_SERVER_ERROR', serverError);
    //                 reject(serverError);
    //             }
    //         });
    // });
  },
  setCardSeries({ commit, state }, card_series) {
    const key = card_series.merchant_id + '-' + card_series.merchant_id
    state.card_series[key] = card_series
  },
}

export const $mutations: MutationTree<CardSeriesFormState> = {
  RESET_STATE: (state) => {
    for (const key in initState) {
      (state as any)[key] = (initState as any)[key]
    }
  },
  START_LOADING: (state) => {
    state.loaded = false
    state.loading = true
    state.fatalError = false
  },
  LOADED_SUCCESS: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = false
  },
  LOADED_ERROR: (state) => {
    state.loaded = true
    state.loading = false
    state.fatalError = true
  },
  SET_SERVER_ERROR: (state, errors) => {
    state.serverErrors = errors
  },

  SET_FORM_DATA(state, { object }) {
    state.object = object

    const formData = cloneDeep(state.object)

    state.form = {
      ...formData,
    }
  },

  GENERATE_FORM_DATA(state, predefined) {
    state.form = {
      ...cloneDeep(EMPTY_OBJECT),
      ...predefined,
    }
  },

  CHANGE_VALUE(state: CardSeriesFormState, { field, newValue }) {
    if (field === 'end_number' || field === 'start_number') {
      state.form[field] = parseInt(newValue)
    }
    else {
      state.form[field] = newValue
    }
  },

  ON_FORM_SAVE(state, { object }) {
    state.object = object

    const formData = cloneDeep(object)

    state.form = {
      ...formData,
    }
  },
  SET_ITEMS_LIST(state, data) {
    state.merchant = data.merchants

    state.lastMeta = {
      page: data.meta.page,
      pageSize: data.meta.per_page,
      totalCount: data.meta.total_count,
      totalPages: data.meta.total_pages,
    }
  },
}

const $getters = {
  merchants: (state: any) => {
    return state.merchant.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
        inn: item.inn,
        legal_address: item.legal_address,
        outlets: item.outlets,
      }
    })
  },
  merch_parg: (state: any) => {
    return state.lastMeta
  },
}

export const STORE_KEY = '$_card_series_form'

export const store = {
  namespaced: true,
  state: $state,
  actions: $actions,
  getters: $getters,
  mutations: $mutations,
}
